import { AuthService } from '@auth0/auth0-angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap } from 'rxjs';
import { AuthActions } from './auth.actions';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthEffects {
  constructor(private actions$: Actions, private authService: AuthService) {}

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.login),
      mergeMap(() =>
        this.authService
          .loginWithPopup(
            {},
            {
              timeoutInSeconds: 1000,
            }
          )
          .pipe(map(() => AuthActions.loginSuccess()))
      )
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logout),
      mergeMap(() =>
        this.authService
          .logout({
            logoutParams: { returnTo: document.location.origin },
          })
          .pipe(map(() => AuthActions.logoutSuccess()))
      )
    )
  );

  signup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.signup),
      mergeMap(() =>
        this.authService
          .loginWithPopup(
            {
              authorizationParams: {
                screen_hint: 'signup',
              },
            },
            {
              timeoutInSeconds: 1000,
            }
          )
          .pipe(map(() => AuthActions.signupSuccess()))
      )
    )
  );
}
