import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { provideAuth0 } from '@auth0/auth0-angular';
import { provideRouter } from '@angular/router';
import { routes } from './app/app.routes';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { isDevMode } from '@angular/core';
import { authReducer } from './app/state/auth/auth.reducer';
import { provideEffects } from '@ngrx/effects';
import { AuthEffects } from './app/state/auth/auth.effects';
import { provideHttpClient } from '@angular/common/http';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { environment } from './environments/environment';

bootstrapApplication(AppComponent, {
  providers: [
    provideAuth0({
      domain: environment.auth0Domain,
      clientId: environment.auth0ClientId,
      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: environment.auth0Audience
      },
      httpTimeoutInSeconds:1000,
    }),
    provideRouter(routes),
    provideStore({ auth: authReducer }),
    provideStoreDevtools({
      name: 'Customer Portal',
      maxAge: 25,
      logOnly: !isDevMode(),
    }),
    provideEffects([AuthEffects]),
    provideAnimationsAsync(),
    provideHttpClient(),
  ],
});
